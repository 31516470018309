// StockDetailsModal.tsx
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useAtom } from "jotai";
import { tokenAtom } from "../state/atoms";
import axios from "axios";

interface StockDetailsModalProps {
  show: boolean;
  onHide: () => void;
  symbol: string;
  portfolioId: number;
  customerId: string;
}

const StockDetailsModal: React.FC<StockDetailsModalProps> = ({ show, onHide, symbol, portfolioId, customerId }) => {
  const [token] = useAtom(tokenAtom);
  const [notes, setNotes] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${portfolioId}/${symbol}`;
      const data = await axios.get(url, config);
      const extractedNotes = data.data.map((item: any) => item.note);
      setNotes(extractedNotes);
    };

    if (show) {
      fetchData();
    }
  }, [show, symbol, portfolioId, customerId, token]);

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{symbol} Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <img
          src={`https://rjore.xyz/portfolio-mini-charts/${symbol.replaceAll(" ", "-")}-${portfolioId}-large.png`}
          alt={`${symbol} large`}
          style={{ width: "100%" }}
        />
        <h5>Transaction History</h5>
        {notes.map((note, index) => (
          <p key={index}>{note}</p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StockDetailsModal;
